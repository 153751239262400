import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AssetsService from "../../../services/assets-service";
import EventsService from "../../../services/events-service";

import Tile from "../../../components/molecules/Tile";
import DateSelectDropdown from "../../../components/molecules/DateSelectDropdown";

import isUUID from "../../../helpers/validation/isUUID";
import exportAsImage from "../../../helpers/export/exportAsImage";
import formatNumber from "../../../helpers/formatting/formatNumber";
import formatShareActivity from "../../../helpers/formatting/formatShareActivity";
import { CALENDAR_OPTIONS, CUSTOM_TIME_RANGE_VALUE, NOT_FOUND_ROUTE_PATH, ASSET_ID_KEY } from "../../../helpers/constants/constants";

import { WarningContext } from "../../../contexts/WarningContext";
import { AssetStateContext } from "../../../contexts/AssetStateContextProvider";

import UsersActivityChart from "./UsersActivityChart";
import SharesActivityChart from "./SharesActivityChart";
import AssetPerformanceChart from "./AssetPerformanceChart";

const assetsService = AssetsService();
const eventsService = EventsService();

const AssetLevelReporting = ({ queryParams }) => {
    const { t } = useTranslation(['assets']);
    const navigate = useNavigate();

    const [assetId, setAssetId] = useState();
    const [asset, setAsset] = useState();

    // Commented until the DCM decided on the correct method of counting versions.
    // const [assetVersion, setAssetVersion] = useState(0);

    const [minDate, setMinDate] = useState(new Date());
    const [endRange, setEndRange] = useState();
    const [startRange, setStartRange] = useState();
    const [selectedOption, setSelectedOption] = useState();
    const [prevOption, setPrevOption] = useState();

    const [isLoading, setIsLoading] = useState(true);
    const [isBarChartLoading, setIsBarChartLoading] = useState(true);
    const [assetPerformance, setAssetPerformance] = useState();
    const [uniqueAssetPerformance, setUniqueAssetPerformance] = useState();
    const [sharesActivity, setSharesActivity] = useState();
    const [showError, setShowError] = useState(false);

    const assetPerformanceRef = useRef();
    const usersActivityRef = useRef();
    const sharesActivityRef = useRef();

    const [, setState] = useContext(WarningContext);
    const [showInactiveAssets, ,] = useContext(AssetStateContext);

    useEffect(() => {
        assetsService.init();
        eventsService.init();

        let assetId = queryParams?.assetId;
        let from = queryParams?.from;
        let to = queryParams?.to;
        let option = queryParams?.option;

        if (!assetId || !from || !to || !option || !isUUID(assetId)) {
            assetId = window.localStorage.getItem(ASSET_ID_KEY);
            if (!assetId || !isUUID(assetId))
                navigate(NOT_FOUND_ROUTE_PATH, { replace: false });
        } else {
            from = new Date(from);
            to = new Date(to);
            option = parseInt(option);
        }

        setAssetId(assetId);
        assetsService
            .get(assetId)
            .then((response => {
                if (!response)
                    navigate(NOT_FOUND_ROUTE_PATH, { replace: false });
                setAsset(response.data);
                let minDate = new Date(response.data?.publishDateTime);
                setMinDate(new Date(new Date(minDate).setDate(minDate.getDate() - 1)));

                if (!(from instanceof Date && !isNaN(from)) || !(to instanceof Date && !isNaN(to))) {
                    setSelectedOption(CALENDAR_OPTIONS[2]);
                } else {
                    if (minDate > from)
                        setStartRange(minDate);
                    else
                        setStartRange(from);
                    setEndRange(to);
                    setSelectedOption(CALENDAR_OPTIONS.find(o => o.value === option));
                }
            })).catch(() => { navigate(NOT_FOUND_ROUTE_PATH, { replace: false }) });

        // Commented until the DCM decided on the correct method of counting versions.
        // assetsService
        //     .getAssetEventsCount(assetId, 2)
        //     .then((response => {
        //         setAssetVersion(response.data);
        //     }));

        return () => {
            assetsService.dispose();
            eventsService.dispose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!endRange || !startRange) return;

        setIsLoading(true);
        eventsService
            .getAssetPerformance(assetId, startRange, endRange, showInactiveAssets)
            .then(response => {
                setAssetPerformance(response.data);
                setSharesActivity(formatShareActivity(response.data));
            })
            .finally(() => setIsLoading(false));

        setIsBarChartLoading(true);
        eventsService
            .getUniqueAssetPerformance(assetId, startRange, endRange, showInactiveAssets)
            .then(response => setUniqueAssetPerformance(response.data))
            .finally(() => setIsBarChartLoading(false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endRange, startRange, showInactiveAssets]);

    useEffect(() => {
        if (prevOption && selectedOption?.value === CUSTOM_TIME_RANGE_VALUE) {
            setEndRange();
            setStartRange();
        }
        setPrevOption(selectedOption?.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOption]);

    const exportAssetPerformance = (format) => {
        exportAsImage(assetPerformanceRef, format, t('asset_performance'));
    }

    const exportUsersActivity = (format) => {
        exportAsImage(usersActivityRef, format, t('users_activity'))
    }

    const exportSharesActivity = (format) => {
        exportAsImage(sharesActivityRef, format, t('shares_activity'))
    }

    const closeError = () => setShowError(false);

    useEffect(() => {
        setState({
            message: t('common:file_export_error'),
            bc_color: '#F8D7DA',
            show: showError,
            close: closeError
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showError]);

    return (
        <div className="row">
            <div className="col-12 mb-3">
                <DateSelectDropdown
                    minDate={minDate}
                    setEndRange={setEndRange}
                    setStartRange={setStartRange}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    header={asset?.name}
                    from={startRange}
                    to={endRange}>
                    {/* {                       // Commented until the DCM decided on the correct method of counting versions.
                        assetVersion > 0 &&
                        <div className="px-3 mb-2 mb-md-0">
                            <span className="d-flex align-items-center bc-grey-400 border-radius text-md fw-bold c-brand-dark-blue py-1 ps-3 pe-2">
                                <span className="pe-1">{t('common:version')}&nbsp;{assetVersion}</span>
                                <Tooltip
                                    anchor={`tooltip-anchor-version`}
                                    description={t('common:version_tooltip')}
                                    place="bottom" />
                            </span>
                        </div>
                    } */}
                </DateSelectDropdown>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                <Tile
                    name="total-downloads"
                    title={t('total_downloads')}
                    value={formatNumber(assetPerformance?.totalDownloads)}
                    value2={assetPerformance?.downloadsTrend}
                    description={t('total_downloads_tooltip')}
                    isLoading={isLoading} />
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                <Tile
                    name="total-shares"
                    title={t('total_shares')}
                    value={formatNumber(assetPerformance?.totalShares)}
                    value2={assetPerformance?.sharesTrend}
                    description={t('total_shares_tooltip')}
                    isLoading={isLoading} />
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                <Tile
                    name="total-previews"
                    title={t('total_previews')}
                    value={formatNumber(assetPerformance?.totalPreviews)}
                    value2={assetPerformance?.previewsTrend}
                    description={t('total_previews_tooltip')}
                    isLoading={isLoading} />
            </div>
            <div ref={assetPerformanceRef} className="col-12 mb-4">
                <AssetPerformanceChart
                    data={assetPerformance?.performances}
                    isLoading={isLoading}
                    exportChart={exportAssetPerformance} />
            </div>
            <div ref={sharesActivityRef} className="col-12 col-lg-6 mb-4">
                <SharesActivityChart
                    data={sharesActivity}
                    isLoading={isLoading}
                    exportChart={exportSharesActivity}
                    timePeriod={`${t('date:day_month_year', { date: new Date(startRange) })} - ${t('date:day_month_year', { date: new Date(endRange) })}`}
                />
            </div>
            <div ref={usersActivityRef} className="col-12 col-lg-6 mb-4">
                <UsersActivityChart
                    data={uniqueAssetPerformance}
                    isLoading={isBarChartLoading}
                    exportChart={exportUsersActivity}
                    timePeriod={`${t('date:day_month_year', { date: new Date(startRange) })} - ${t('date:day_month_year', { date: new Date(endRange) })}`}
                />
            </div>
        </div>
    );
}

export default AssetLevelReporting;
