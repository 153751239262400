
import i18n from "./core/i18n";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import Header from "./core/Header";

import ForbiddenPage from "./pages/Secure/ForbiddenPage";
import UnauthorizedPage from "./pages/Secure/UnauthorizedPage";
import ServerErrorPage from "./pages/Secure/ServerErrorPage";
import SessionExpiredPage from "./pages/Secure/SessionExpiredPage";
import NotFoundPage from "./pages/Secure/NotFoundPage";
import LogoutPage from "./pages/Secure/LogoutPage";

import DamReportingLayoutPage from "./pages/DamReporting/DamReportingLayoutPage";
import UserReportingLayoutPage from "./pages/UserReporting/UserReportingLayoutPage";
import AssetReportingLayoutPage from "./pages/AssetReporting/AssetReportingLayoutPage";

import WarningBanner from "./components/atoms/WarningBanner";
import { WarningContextProvider } from "./contexts/WarningContext";
import { TokenCheckContextProvider } from "./contexts/TokenCheckContextProvider";
import { AssetStateContextProvider } from "./contexts/AssetStateContextProvider";

import {
  DEFAULT_ROUTE,
  USER_REPORTING_ROUTE,
  UNAUTHORIZED_ROUTE_PATH,
  SERVER_ERROR_ROUTE_PATH,
  FORBIDDEN_ROUTE_PATH,
  SESSION_EXPIRED_ROUTE_PATH,
  ASSET_ROUTE_PATH,
  ASSET_REPORTING_ROUTE_PATH,
} from "./helpers/constants/constants";
import AuthDamRoute from "./core/AuthDamRoute";
import AuthAssetRoute from "./core/AuthAssetRoute";
import AssetLevelReporting from "./pages/AssetReporting/components/AssetLevelReporting";

const App = () => {

  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <TokenCheckContextProvider>
          <AssetStateContextProvider>
            <WarningContextProvider>
              <div className="app">
                <div className="header fixed-top bc-white border-bottom-grey-200">
                  <Header></Header>
                </div>
                <div className="w-100 height-100 d-flex flex-column header-margin bc-grey-200" style={{ overflowY: "scroll", overflowX: "hidden" }}>
                  <WarningBanner />
                  <ErrorBoundary fallback={<ServerErrorPage />}>
                    <div className="d-flex flex-column container-fluid page-max-width flex-grow-1 px-4 pt-3 pb-2">
                      <Routes>
                        <Route path={FORBIDDEN_ROUTE_PATH} element={<ForbiddenPage />} />
                        <Route path={UNAUTHORIZED_ROUTE_PATH} element={<UnauthorizedPage />} />
                        <Route path={SERVER_ERROR_ROUTE_PATH} element={<ServerErrorPage />} />
                        <Route path={SESSION_EXPIRED_ROUTE_PATH} element={<SessionExpiredPage />} />
                        <Route path="/logout" element={<LogoutPage />} />
                        <Route path="" element={<AuthDamRoute />}>
                          <Route path={DEFAULT_ROUTE} element={<DamReportingLayoutPage />} />
                          <Route path={USER_REPORTING_ROUTE} element={<UserReportingLayoutPage />} />
                          <Route path={ASSET_ROUTE_PATH} element={<AssetReportingLayoutPage />} />
                        </Route>
                        <Route path={ASSET_REPORTING_ROUTE_PATH} element={<AuthAssetRoute />}>
                          <Route path={ASSET_REPORTING_ROUTE_PATH} element={<AssetLevelReporting />} />
                        </Route>
                        <Route path="*" exact element={<NotFoundPage />} />
                      </Routes>
                    </div>
                  </ErrorBoundary>
                </div>
              </div>
            </WarningContextProvider>
          </AssetStateContextProvider>
        </TokenCheckContextProvider>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
