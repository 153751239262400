import Modal from "react-bootstrap/Modal";
import { useTranslation, Trans } from "react-i18next";

const TermsModal = ({ modalOpen, setModalOpen }) => {
    const { t } = useTranslation(['common', 'terms']);
    const currentYear = new Date().getFullYear();
    return (
        <div>
            <Modal show={modalOpen} onHide={() => setModalOpen(false)} dialogClassName={"primaryModal"}>
                <Modal.Body>
                    <div className="text-end">
                        <div className="cursor-pointer"
                            onClick={() => setModalOpen(false)}>
                            <img alt="close" src={`${process.env.PUBLIC_URL}/images/icons/close.svg`} />
                        </div>
                    </div>
                    <h1 className="ms-2 ms-md-5 py-4">{t("terms:general_terms_of_use")}</h1>
                    <div className="modal-overflow mx-2 mx-md-5 pe-2">
                        <div className="pb-4">
                            <h5>1.  {t("terms:web_solutions")}</h5>

                            <p>
                                &emsp;<Trans i18nKey="terms:web_solutions_1" />
                            </p>
                            <p>
                                &emsp;<Trans i18nKey="terms:web_solutions_2" />
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>2.  {t("terms:exclusion_of_warranties")}</h5>

                            <p>
                                &emsp;{t("terms:exclusion_of_warranties_1")}
                            </p>
                            <p>
                                &emsp;{t("terms:exclusion_of_warranties_2")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>3.	{t("terms:limitation_of_liability")}</h5>

                            <p>
                                &emsp;{t("terms:limitation_of_liability_1")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>4.	{t("terms:indemnity")}</h5>

                            <p>
                                &emsp;{t("terms:indemnity_1")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>5.	{t("terms:information_provided_electronically")}</h5>

                            <p>
                                &emsp;{t("terms:information_provided_electronically_1")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>6.	{t("terms:links_and_software")}</h5>

                            <p>
                                &emsp;{t("terms:links_and_software_1")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>7.	{t("terms:damage_to_others")}</h5>

                            <p>
                                &emsp;{t("terms:damage_to_others_1")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>8.	{t("terms:governing_law")}</h5>

                            <p>
                                &emsp;{t("terms:governing_law_1")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>9.	{t("terms:privacy")}</h5>

                            <p>
                                &emsp;<Trans i18nKey="terms:privacy_1">Link <a href="https://www.datacm.com/privacy-policy/" target="_blank" rel="noreferrer">https://www.datacm.com/privacy-policy/</a></Trans>
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>10.	{t("terms:intellectual_property")}</h5>

                            <p>
                                &emsp;{t("terms:intellectual_property_1")}
                            </p>
                            <p>
                                &emsp;{t("terms:intellectual_property_2", { currentYear })}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>11.	{t("terms:restrictions_on_use")}</h5>

                            <p>
                                &emsp;{t("terms:restrictions_on_use_1")}
                            </p>
                            <p>
                                &emsp;{t("terms:restrictions_on_use_2")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>12.	{t("terms:reserve_of_rights")}</h5>

                            <p>
                                &emsp;{t("terms:reserve_of_rights_1")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>13.	{t("terms:survival_of_obligations")}</h5>

                            <p>
                                &emsp;{t("terms:survival_of_obligations_1")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>14.	{t("terms:registration")}</h5>

                            <p>
                                &emsp;{t("terms:registration_1")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>15.	{t("terms:products_and_services")}</h5>

                            <p>
                                &emsp;{t("terms:products_and_services_1")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>16.	{t("terms:orders_products")}</h5>

                            <p>
                                &emsp;{t("terms:orders_products_1")}
                            </p>
                        </div>
                    </div>
                    <div className="text-center py-4">
                        <button onClick={() => setModalOpen(false)} type="button" className="btn btn-outline-dark btn-lg rounded-5 px-4">{t("close")}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default TermsModal;